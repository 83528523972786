<template>
  <b-row class="match-height mt-2">
    <b-col
      :key="index"
      v-for="(template, index) in chosenTemplates"
      md="6"
      lg="3"
    >
      <div
        class="text-body"
        @click="navigateToTemplate(template)"
      >
        <b-card
          :img-src="template.imageBase64 || imagelink"
          img-alt="Card image cap"
          img-top
          :title="template.name"
          class="image-item"
        >
          <b-card-text>
            {{ template.documentation.description }}
             
          </b-card-text>
     
          <b-button @click.stop="apiRequest(template)">API request</b-button>
 
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink, BCardText, BCard, BButton } from "bootstrap-vue";
export default {
  components: { BRow, BCol, BLink, BCardText, BCard, BButton },
  props: ["chosenTemplates", "imagelink"],
  methods: {
    navigateToTemplate(template){

      if(this.$store.state.authStore.currentUser.role === 'DEVELOPER'){
        this.$router.push({ name: 'developer-template-to-run', params: { id: template.id }, query: this.$route.query });
      }
      else {
        this.$router.push({ name: 'template-to-run', params: { id: template.id }, query: this.$route.query });
      }
      
    },
    apiRequest(template){
        this.$router.push({ name: 'developer-template-to-run', params: { id: template.id }, query: this.$route.query });
    }
  }
};
</script>
<style scoped>
  .text-body {
    cursor: pointer;
  }
</style>