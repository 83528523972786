<template>
  <b-row class="match-height displayContents">
    <div class="demo-inline-spacing">
      <b-badge
        :variant="selectedLabel[0] == label ? 'primary' : 'light-primary'"
        :key="index"
        v-for="(label, index) in computedLabels"
        class="hoverElement"
        v-model="selectedLabel"
        :value="label"
        @click="updateSelectedLabel([label])"
      >
        {{ label }}
      </b-badge>
    </div>
  </b-row>
</template>

<script>
import { BRow, BButton, BBadge } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: { BRow, BButton, BBadge },
  props: ["computedLabels", "updateSelectedLabel", "selectedLabel"],
};
</script>

<style>
.hoverElement:hover {
  cursor: pointer !important;
}
</style>
