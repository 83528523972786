<template>
  <div class="main-templates">
    <b-row>
      <b-col lg="2" md="2">
        <category-list
          v-if="categoriesToDisplay.length > 0"
          :selectCategory="selectCategory"
          :getSelectedCategoryFromState="getSelectedCategoryFromState"
          :categoriesToDisplay="categoriesToDisplay"
        >
        </category-list>
      </b-col>
      <b-col lg="10" md="10">
        <div class="ecommerce-searchbar">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              class="search-product"
              @input="debounceSearch"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </div>

        <labels
          :computedLabels="computedLabels"
          :updateSelectedLabel="updateSelectedLabel"
          :selectedLabel="selectedLabel"
        ></labels>

        <templates-cards
          :chosenTemplates="chosenTemplates"
          :imagelink="imagelink"
        ></templates-cards>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ALL_CATEGORIES,
  ALL_LABELS,
} from "../../../contilt/configs/inputconstants";
import Labels from "./labels/labels.vue";
import CategoryList from "./categories/categoryList.vue";
import TemplatesCards from "./templatesCards/templatesCards.vue";

import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BCol,
    Labels,
    CategoryList,
    TemplatesCards,
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      selectedLabel: ["All"],
      imagelink: `https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png`,
      searchTerm: "",
      debounce: null,
      loading: true,
    };
  },
  created() {
    const appLoading = document.getElementById("loading-bg");
    if (this.loading) {
      appLoading.style.display = "contents";
    }
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.dispatch("templatesStore/getCategories").then((res) => {
      this.loading = false;
      appLoading.style.display = "none";
    });
  },
  computed: {
    computedLabels() {
      //labels to be shown
      let labelsToShow = {};
      labelsToShow[ALL_LABELS] = ALL_LABELS;
      const selectCategory =
        this.$store.state.templatesStore.selectedCategory.value;

      this.categoryAllTemplates.map((template) => {
        template.labels.map((label) => {
          labelsToShow[label] = label;
        });
      });

      if (selectCategory === ALL_CATEGORIES) {
        return Object.values(labelsToShow);
      } else {
        let labelsHelper = {};
        labelsHelper[ALL_LABELS] = ALL_LABELS;
        //each label from all templates -> should be existed in this categories own labels list
        const categoryLabels = this.$store.state.templatesStore.labels[
          selectCategory
        ]
          ? this.$store.state.templatesStore.labels[selectCategory]
          : [];

        Object.values(labelsToShow).map((item) => {
          if (categoryLabels.findIndex((data) => data.value == item) >= 0) {
            labelsHelper[item] = item;
          }
        });

        return Object.values(labelsHelper);
      }
    },

    chosenTemplates() {
      const labelSelected = this.selectedLabel[0];
      debugger;
      let dataToReturn = [];
      if (this.$store.state.templatesStore.selectedCategory.id === "All") {
        if (this.selectedLabel[0] === ALL_LABELS) {
          dataToReturn = this.categoryAllTemplates.filter(x=>x.categories.indexOf("API") == -1);
        } else {
          let newTemplates = [];
          this.$store.state.templatesStore.templates.map((template) => {
            if (template.labels.includes(this.selectedLabel[0])) {
              //getContainedTemplatesLabels()
              newTemplates.push(template);
            }
          });
          dataToReturn = newTemplates;
        }
      } else {
        if (labelSelected == "All") {
          dataToReturn = this.categoryAllTemplates;
        } else {
          //return the 1-templates in this category, 2- that have the selected label
          let newFilteredTemplates = this.getContainedTemplatesLabels(
            this.categoryAllTemplates,
            labelSelected
          );
          dataToReturn = newFilteredTemplates;
        }
      }

      const searchResult = this.getSearchResult;
      if (this.searchTerm.length > 0) {
        //here we have search results, display only templates that areexisted in dataToReturn too.
        if (searchResult.length == 0) {
          dataToReturn = [];
        } else {
          let newData = [];
          const searchSet = new Set([]);
          searchResult.map((elm) => {
            searchSet.add(elm.ref);
          });
          dataToReturn.map((item) => {
            if (searchSet.has(item.id)) {
              newData.push(item);
            }
          });
          return newData;
        }
      }
      return dataToReturn;
    },
    categoryAllTemplates() {
      if (this.$store.state.templatesStore.selectedCategory.id === "All") {
        return this.$store.state.templatesStore.templates;
      } else {
        let newTemplates = this.getContainedTemplatesInCategory(
          this.$store.state.templatesStore.templates,
          this.$store.state.templatesStore.selectedCategory.value
        );
        return newTemplates;
      }
    },
    getSelectedCategoryFromState() {
      if (this.$store.state.templatesStore.selectedCategory) {
        return this.$store.state.templatesStore.selectedCategory.value;
      }
      return "All";
    },
    categoriesToDisplay() {
      const counterObject = { All: "All" };
      let categoriesAfterFilter = [];

      this.$store.state.templatesStore.templates.map((item) => {
        item.categories.map((elm) => {
          counterObject[elm] = elm;
        });
      });

      this.$store.state.templatesStore.categories.map((elm) => {
        if (counterObject[elm.value]) {
          categoriesAfterFilter.push({ id: elm.id, value: elm.value });
        }
      });

      return categoriesAfterFilter;
    },
    getSearchResult() {
      return this.$store.state.lunrSearchTemplates.searchResult;
    },
  },
  methods: {
    selectCategory(label) {
      this.$store.commit("templatesStore/setSelectedCategory", label); //set chosen category
      this.selectedLabel = ["All"]; //clear selected label/filter
    },
    getContainedTemplatesInCategory(containingArray, value) {
      let result = [];
      containingArray.map((item) => {
        if (item.categories.includes(value)) {
          result.push(item);
        }
      });
      return result;
    },
    getContainedTemplatesLabels(containingArray, value) {
      let result = [];
      containingArray.map((item) => {
        if (item.labels.includes(value)) {
          result.push(item);
        }
      });
      return result;
    },
    search() {
      this.$store.dispatch("lunrSearchTemplates/search", this.searchTerm);
    },
    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search();
      }, 100);
    },
    updateSelectedLabel(label) {
      this.selectedLabel = label;
    },
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.dispatch("lunrSearchTemplates/search", "");
  },
};
</script>
<style lang="scss">
@import "@core/scss/base/pages/app-ecommerce.scss";
</style>

<style>
.displayContents {
  display: contents;
}

body .content-detached.content-right .content-body {
  margin-left: auto;
}

.image-item img {
  height: 150px;
  object-fit: cover;
}

.image-item {
  transition: all 0.3s ease;
}

.image-item:hover {
  filter: brightness(1.1);
  scale: 1.03;
}
</style>
