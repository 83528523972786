<template>
  <div>
    <b-card>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        @click="selectCategory(label)"
        :key="index"
        :block="true"
        class="catgory-list-item"
        v-for="(label, index) in categoriesToDisplay"
      >
        {{ label.value }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BButton,
  },
  props: [
    "selectCategory",
    "getSelectedCategoryFromState",
    "categoriesToDisplay",
  ],
};
</script>

<style>
.categoiry-button {
  border: none;
  border-radius: 15px !important;
  /* text-align: center; */
  padding: 0;
  padding-left: 17px;
  width: 100%;
  margin-bottom: 5px;
}

.catgory-list-item {
  text-align: left;
  padding-left: 20px;
  transition: all 0.3s ease;
}

.catgory-list-item:hover {
  padding-left: 30px;
}

.categoiry-button:hover {
  background-color: white !important;
}
</style>
